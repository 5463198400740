var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.tabsGeneralConditions && _vm.tabsGeneralConditions.length
        ? _c(
            "vertical-tabs-component",
            {
              attrs: { tabs: _vm.tabsGeneralConditions, isManyContents: false },
              on: { "set-tab": _vm.setTab }
            },
            [
              _vm.tabCondicoes.UniqueId
                ? [
                    _c("jodit-editor", {
                      attrs: {
                        config: _vm.joditConfig,
                        id: _vm.tabCondicoes.UniqueId
                      },
                      model: {
                        value: _vm.tabCondicoes.Value,
                        callback: function($$v) {
                          _vm.$set(_vm.tabCondicoes, "Value", $$v)
                        },
                        expression: "tabCondicoes.Value"
                      }
                    }),
                    _c(
                      "vs-row",
                      {
                        staticClass: "mt-5",
                        attrs: { "vs-type": "flex", "vs-justify": "end" }
                      },
                      [
                        _c(
                          "vs-col",
                          {
                            attrs: {
                              "vs-type": "flex",
                              "vs-justify": "end",
                              "vs-align": "center",
                              "vs-lg": "3",
                              "vs-sm": "12"
                            }
                          },
                          [
                            _c(
                              "vs-button",
                              {
                                staticClass: "button-primary text-center",
                                on: {
                                  click: function($event) {
                                    return _vm.updateConditions()
                                  }
                                }
                              },
                              [_vm._v("\n            Salvar\n          ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : [
                    _c(
                      "div",
                      { staticClass: "table-content" },
                      [
                        _vm.tabCondicoes.length > 0
                          ? [
                              _c(
                                "vs-table",
                                {
                                  staticClass: "table-list",
                                  attrs: {
                                    data: _vm.tabCondicoes,
                                    hoverFlat: true,
                                    noDataText: "Nenhum histórico encontrado"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var data = ref.data
                                          return _vm._l(data, function(
                                            item,
                                            indextr
                                          ) {
                                            return _c(
                                              "vs-tr",
                                              {
                                                key: indextr,
                                                attrs: { data: item }
                                              },
                                              [
                                                _c(
                                                  "vs-td",
                                                  {
                                                    attrs: {
                                                      data: data[indextr].User
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          item.User || "-"
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "vs-td",
                                                  {
                                                    staticClass: "text-center",
                                                    attrs: {
                                                      data: item.CreateDate
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm._f("moment")(
                                                            item.CreateDate,
                                                            "DD/MM/YYYY"
                                                          )
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "vs-td",
                                                  {
                                                    attrs: {
                                                      data: item.BranchName
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          item.BranchName
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "vs-td",
                                                  [
                                                    _c(
                                                      "vs-dropdown",
                                                      {
                                                        staticClass:
                                                          "btn-action-apolice",
                                                        attrs: {
                                                          "vs-custom-content":
                                                            "",
                                                          "vs-trigger-click": ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "vs-button",
                                                          {
                                                            attrs: {
                                                              color: "dark",
                                                              type: "flat",
                                                              title: "Ações"
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "onpoint-dots-three-vertical icon-dots-three-vertical",
                                                              attrs: {
                                                                role: "button"
                                                              }
                                                            })
                                                          ]
                                                        ),
                                                        _c(
                                                          "vs-dropdown-menu",
                                                          {
                                                            staticClass:
                                                              "btn-action-apolice-options"
                                                          },
                                                          [
                                                            _c(
                                                              "vs-dropdown-item",
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "text-base text-nowrap",
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.openModalConfitionsDiff(
                                                                          item
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Visualizar alteração"
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          })
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1219860426
                                  )
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "thead" },
                                    [
                                      _c("vs-th", [_vm._v("Usuário")]),
                                      _c(
                                        "vs-th",
                                        { staticClass: "text-center" },
                                        [_vm._v("Data da ação")]
                                      ),
                                      _c("vs-th", [_vm._v("Condição")]),
                                      _c("vs-th")
                                    ],
                                    1
                                  )
                                ],
                                2
                              ),
                              _c(
                                "vs-popup",
                                {
                                  attrs: {
                                    id: "show-diff",
                                    title: "Histórico de alterações",
                                    active: _vm.showModalConfitionsDiff
                                  },
                                  on: {
                                    "update:active": function($event) {
                                      _vm.showModalConfitionsDiff = $event
                                    }
                                  }
                                },
                                [
                                  _c("div", { staticClass: "vw-row mt-3" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "vx-col pt-5 w-full mt-2"
                                      },
                                      [
                                        _c(
                                          "vs-collapse",
                                          { attrs: { type: "margin" } },
                                          [
                                            _c("vs-collapse-item", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "vx-row",
                                                  attrs: { slot: "header" },
                                                  slot: "header"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "w-full flex flex-wrap"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "title"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          Condições Gerais - Setor\n                          " +
                                                              _vm._s(
                                                                _vm
                                                                  .confitionsDiff
                                                                  .BranchName
                                                              ) +
                                                              "\n                        "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "subtitle"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "moment"
                                                                )(
                                                                  _vm
                                                                    .confitionsDiff
                                                                    .CreateDate,
                                                                  "DD/MM/YYYY HH:mm"
                                                                )
                                                              ) +
                                                              "\n                          - Última alteração\n                        "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "collapse-item-body"
                                                },
                                                [
                                                  _c("pre", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.confitionsDiff
                                                          .textHighlight
                                                      )
                                                    }
                                                  })
                                                ]
                                              )
                                            ]),
                                            _c("vs-collapse-item", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "vx-row",
                                                  attrs: { slot: "header" },
                                                  slot: "header"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "w-full flex flex-wrap"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "title"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          Condições Gerais - Setor\n                          " +
                                                              _vm._s(
                                                                _vm
                                                                  .confitionsDiff
                                                                  .BranchName
                                                              ) +
                                                              "\n                        "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "subtitle"
                                                        },
                                                        [_vm._v("Original")]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "collapse-item-body"
                                                },
                                                [
                                                  _c("pre", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.confitionsDiff
                                                          .textOldHighlight
                                                      )
                                                    }
                                                  })
                                                ]
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              )
                            ]
                          : [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex flex-col w-full md:w-1/2 mx-auto items-center mt-32"
                                },
                                [
                                  _c("p", [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "material-icons text-6xl"
                                      },
                                      [_vm._v("description")]
                                    )
                                  ]),
                                  _c("p", { staticClass: "text-center" }, [
                                    _vm._v("Nenhum histórico encontrado")
                                  ])
                                ]
                              )
                            ]
                      ],
                      2
                    )
                  ]
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }