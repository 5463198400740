<template>
  <div>
    <vertical-tabs-component
      v-if="tabsGeneralConditions && tabsGeneralConditions.length"
      :tabs="tabsGeneralConditions"
      :isManyContents="false"
      @set-tab="setTab"
    >
      <template v-if="tabCondicoes.UniqueId">
        <jodit-editor
          :config="joditConfig"
          :id="tabCondicoes.UniqueId"
          v-model="tabCondicoes.Value"
        />
        <vs-row vs-type="flex" vs-justify="end" class="mt-5">
          <vs-col
            vs-type="flex"
            vs-justify="end"
            vs-align="center"
            vs-lg="3"
            vs-sm="12"
          >
            <vs-button
              @click="updateConditions()"
              class="button-primary text-center"
            >
              Salvar
            </vs-button>
          </vs-col>
        </vs-row>
      </template>

      <template v-else>
        <div class="table-content">
          <template v-if="tabCondicoes.length > 0">
            <vs-table
              class="table-list"
              :data="tabCondicoes"
              :hoverFlat="true"
              noDataText="Nenhum histórico encontrado"
            >
              <template slot="thead">
                <vs-th>Usuário</vs-th>
                <vs-th class="text-center">Data da ação</vs-th>
                <vs-th>Condição</vs-th>
                <vs-th></vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr
                  :data="item"
                  :key="indextr"
                  v-for="(item, indextr) in data"
                >
                  <vs-td :data="data[indextr].User">
                    {{ item.User || "-" }}
                  </vs-td>

                  <vs-td :data="item.CreateDate" class="text-center">
                    {{ item.CreateDate | moment("DD/MM/YYYY") }}
                  </vs-td>

                  <vs-td :data="item.BranchName">
                    {{ item.BranchName }}
                  </vs-td>

                  <vs-td>
                    <vs-dropdown
                      vs-custom-content
                      vs-trigger-click
                      class="btn-action-apolice"
                    >
                      <vs-button color="dark" type="flat" title="Ações">
                        <i class="onpoint-dots-three-vertical icon-dots-three-vertical" role="button"></i>
                      </vs-button>
                      <vs-dropdown-menu class="btn-action-apolice-options">
                        <vs-dropdown-item>
                          <p
                            class="text-base text-nowrap"
                            @click="openModalConfitionsDiff(item)"
                          >
                            <span>Visualizar alteração</span>
                          </p>
                        </vs-dropdown-item>
                      </vs-dropdown-menu>
                    </vs-dropdown>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>

            <vs-popup
              id="show-diff"
              title="Histórico de alterações"
              :active.sync="showModalConfitionsDiff"
            >
              <div class="vw-row mt-3">
                <div class="vx-col pt-5 w-full mt-2">
                  <vs-collapse type="margin">
                    <vs-collapse-item>
                      <div slot="header" class="vx-row">
                        <div class="w-full flex flex-wrap">
                          <div class="title">
                            Condições Gerais - Setor
                            {{ confitionsDiff.BranchName }}
                          </div>

                          <div class="subtitle">
                            {{
                              confitionsDiff.CreateDate
                                | moment("DD/MM/YYYY HH:mm")
                            }}
                            - Última alteração
                          </div>
                        </div>
                      </div>

                      <div class="collapse-item-body">
                        <pre v-html="confitionsDiff.textHighlight"></pre>
                      </div>
                    </vs-collapse-item>

                    <vs-collapse-item>
                      <div slot="header" class="vx-row">
                        <div class="w-full flex flex-wrap">
                          <div class="title">
                            Condições Gerais - Setor
                            {{ confitionsDiff.BranchName }}
                          </div>

                          <div class="subtitle">Original</div>
                        </div>
                      </div>

                      <div class="collapse-item-body">
                        <pre v-html="confitionsDiff.textOldHighlight"></pre>
                      </div>
                    </vs-collapse-item>
                  </vs-collapse>
                </div>
              </div>
            </vs-popup>
          </template>

          <template v-else>
            <div
              class="flex flex-col w-full md:w-1/2 mx-auto items-center mt-32"
            >
              <p><span class="material-icons text-6xl">description</span></p>
              <p class="text-center">Nenhum histórico encontrado</p>
            </div>
          </template>
        </div>
      </template>
    </vertical-tabs-component>
  </div>
</template>

<script>
import VerticalTabsComponent from "@/components/vertical-tabs/VerticalTabsComponent";
import { Jodit, JoditEditor } from "jodit-vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { VueEditor, Quill } from "vue2-editor";
const Block = Quill.import("blots/block");
Block.tagName = "DIV";
Quill.register(Block, true);

export default {
  components: {
    VerticalTabsComponent,
    VueEditor
  },
  data() {
    return {
      joditConfig: { language: "pt_br" },
      tabCondicoesParameter: {},
      tabIndex: null,
      focusEditor: false,
      tabCondicoes: {},
      showModalConfitionsDiff: false,
      confitionsDiff: {},
      tabName: null,
      customToolbar: [
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }]
      ]
    };
  },
  computed: {
    ...mapGetters("parametrizacao-module", ["tabsGeneralConditions"])
  },
  methods: {
    ...mapActions("seguradora-module", ["saveModalityObject"]),
    ...mapActions("parametrizacao-module", [
      "getGeneralConditions",
      "getHistoryGeneralConditions"
    ]),
    ...mapMutations("parametrizacao-module", [
      "updateTabsGeneralConditionsChanged"
    ]),
    setupEditor() {
      Jodit.defaultOptions.controls.font.list = {
        "Arial Narrow": "Arial Narrow",
        Calibri: "Calibri",
        "Arial,Helvetica,sans-serif": "Arial",
        "Impact,Charcoal,sans-serif": "Impact",
        "Tahoma,Geneva,sans-serif": "Tahoma",
        "'Times New Roman',Times,serif": "Times New Roman",
        "Verdana,Geneva,sans-serif": "Verdana",
        "Roboto Medium,Arial,sans-serif": "Roboto"
      };
      Jodit.defaultOptions.controls.fontsize.list = {
        12.5: "12.5",
        15: "15",
        18: "18",
        24: "24",
        24: "24",
        36: "36",
        48: "48",
        56: "56",
        70: "70",
        90: "90"
      };
    },
    loadGetGeneralCondition() {
      this.$onpoint.loading(() => {
        return this.getGeneralConditions()
          .catch(ex => {
            this.$onpoint.errorModal(ex.response.data.Errors);
          })
          .then(() => {
            this.loadGetHistoryGeneralConditions();
          });
      });
    },

    loadGetHistoryGeneralConditions() {
      this.$onpoint.loading(() => {
        return this.getHistoryGeneralConditions().catch(ex => {
          this.$onpoint.errorModal(ex.response.data.Errors);
        });
      });
    },

    async updateConditions() {
      await this.$onpoint.loading(async() => {
        let payload = this.$utils.deepClone(this.tabCondicoes);
        delete payload["UniqueId"];

        return await this.saveModalityObject(payload)
          .then(response => {
            this.updateTabsGeneralConditionsChanged({
              response: response,
              tab: this.tabName
            });

            this.$onpoint.successModal(
              "Condições gerais atualizadas com sucesso"
            );
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex.response.data.Errors);
          });
      });
    },

    setTab(event) {
      this.tabName = this.tabsGeneralConditions[event.index].tab;
      this.tabCondicoes = this.$utils.deepClone(
        this.tabsGeneralConditions[event.index].data
      );
      this.focusEditor = true;
      this.$forceUpdate();
    },

    openModalConfitionsDiff(item) {
      this.showModalConfitionsDiff = true;
      this.confitionsDiff = {
        ...item,
        textHighlight: this.highlight(item.TextOld, item.TextNew),
        textOldHighlight: this.highlight(item.TextNew, item.TextOld)
      };
    },

    highlight(oldText, newText) {
      var text = "";
      newText.split("").forEach(function(val, i) {
        if (val != oldText.charAt(i))
          text += "<span class='highlight'>" + val + "</span>";
        else text += val;
      });
      return text;
    }
  },
  async beforeMount() {
    this.loadGetGeneralCondition();
  },
  created() {
    this.setupEditor();
    this.$appInsights.trackPageView({
      name: "param-condicoes",
      url: window.location.href
    });
  }
};
</script>

<style lang="scss">
.ql-editor {
  max-height: 55vh !important;
}

#show-diff {
  .vs-popup {
    width: 1231px;
    max-width: 90%;

    .vs-collapse-item {
      border: 1px solid #b9b9b9 !important;
      box-sizing: border-box;
      box-shadow: 0 0;
      border-radius: 4px;

      .vs-collapse-item--header {
        margin: 0px 24px;
        color: #50555a;

        .title {
          font-weight: bold;
          font-size: 19px;
          line-height: 24px;
        }

        .subtitle {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          padding-left: 24px;
          align-self: center;
        }

        .vs-icon {
          font-size: 25px;
        }
      }

      .con-content--item {
        padding: 0;
      }

      .vs-collapse-item--content {
        .collapse-item-body {
          border-top: 2px solid #b9b9b9 !important;
          padding: 24px 43px 43px;
          .highlight {
            color: red;
            font-weight: 700;
          }
        }
      }
    }

    .vs-collapse.margin .vs-collapse-item {
      margin-top: 0;
      margin-bottom: 24px;
    }
  }
}
</style>

<style lang="scss" scoped>
.table-list {
  ::v-deep .vs-table--thead {
    tr {
      background: transparent !important;
    }
  }

  ::v-deep .vs-table--td {
    padding: 31px 15px !important;
    margin-bottom: 1em;
  }

  ::v-deep .tr-values {
    margin-bottom: 5px;
    border-left: solid 8px rgba(var(--vs-warning), 1);
    border-bottom: solid 10px #f8f8f8;
  }
}

.text-nowrap {
  white-space: nowrap;
}

.icon-dots-three-vertical::before{
  position: relative;
  top: 2px;
  font-size: 24px;
  color: #afafaf;
}
</style>
